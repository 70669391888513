<template>
  <div id="rate-quote">
    <header>
      <img src="@/assets/images/logo-clearmortgagecapital White.svg" alt="">
    </header>
    <div class="container-fluid rate-quote-funnel">
      <div class="row">
        <div class="col-lg-6 p-0 align-center my-auto">
          <div class="rate-quote-funnel__text">
            <h1 class="rate-quote-funnel--title">
              Get Your Custom Quote Today
            </h1>
            <p class="rate-quote-funnel--subtitle">
              Mortgage rates change daily and vary depending on your unique situation. Get your FREE customized quote here!
            </p>
            <form class="form-group" v-on:submit.prevent="saveCode">
              <input type="number" class="form-control" v-model="zipCode" placeholder="Enter Zip Code" required>
              <button type="submit">
                GO
              </button>
            </form>
          </div>
        </div>
        <div class="col-lg-6 p-0">
          <div class="rate-quote-funnel__info">
            <v-avatar rounded :size="130" :src="agentPhoto(agentInfo.photo)" />
            <h2 class="rate-quote-funnel__info--name">
              {{ agentInfo.firstName }} {{ agentInfo.lastName }}
            </h2>
            <span class="rate-quote-funnel__info--position">
              {{ agentInfo.position }}  |  NMLS {{ agentInfo.nmls }}
            </span>
            <a class="rate-quote-funnel__info--email" :href="'mail:' + agentInfo.email">
              {{ agentInfo.email }}
            </a>
            <a class="rate-quote-funnel__info--phone" :href="'tel:' + agentInfo.phone">
              {{ agentInfo.phone | VMask('(###) ###-####') }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RateQuoteFunnel',
  data() {
    return {
      agentInfo: {},
      zipCode: '',
      loading: false
    }
  },
  methods: {
    getAgentInfo() {
      this.loading = true
        this.$http.get(`/api/v1/uset/get-agent/${this.$route.params.id}`)
        .then(response => {
          this.agentInfo = response.data.user
          this.loading = false
        })
        .catch((e) => {
          if (e.response.status === 404) {
            this.$router.push({name: 'PageNoFound'})
          }
        })
    },
    saveCode() {
      if (this.zipCode.length > 0) {
        window.location.href = process.env.VUE_APP_API_URL +
        '/loan-officer/' + this.agentInfo.id + '/' + this.agentInfo.firstName + this.agentInfo.lastName + '?zip=' + this.zipCode
      }
    }
  },
  beforeMount() {
    this.getAgentInfo()
  }
}
</script>

<style lang="scss" scoped>

header {
  position: absolute;
  top: 40px;
  z-index: 2;
  padding-left: 20px;

  @media (min-width: 568px) {
    padding-left: 40px;
  }

  @media (min-width: 1024px) {
    padding-left: 120px;
  }
}
#rate-quote {
  background: #001441;

  @media (min-width: 1024px) {
    background: none;
  }
}

.rate-quote-funnel {
  height: 100vh;

  .form-group {
    display: flex;
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }

    input {
      border-radius: 8px 0 0 8px;
      height: 48px;
      border: 1px solid rgba(38,111,229,0.6);

    }

    button {
      width: 80px;
      height: 48px;
      background: linear-gradient(180deg, #11BB38 0%, #06B12D 100%);
      box-shadow: inset 0 -2px 0 0 #0E9F30, inset 0 2px 1px 0 #13CE3E;
      border-color: transparent;
      color: #FFFFFF;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 21px;
      text-align: center;
      text-shadow: 0 1px 0 0 rgba(0,0,0,0.1);
      border-radius: 0 8px 8px 0;
      margin-left: -1px;
      font-weight: normal;
    }
  }

  &__text {
    position: relative;
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #0036B0;
    margin: 120px auto 0 auto;
    padding: 30px 22px;
    border-radius: 4px;
    align-items: center;
    text-align: center;

    @media (min-width: 568px) {
      padding: 72px 66px;
      margin-top: 120px;
    }

    @media (min-width: 1024px) {
      width: 100%;
      padding: 0 120px;
      height: 100vh;
      border-radius: 0;
      align-items: initial;
      text-align: initial;
      margin: 0;
    }

    &::after {
      position: absolute;
      display: block;
      content: '';
      bottom: -20px;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-top: 20px solid #0036B0;

      @media (min-width: 1024px) {
        right: -50px;
        top: 50%;
        bottom: initial;
        left: initial;
        transform: initial;
        border-top: 30px solid transparent;
        border-bottom: 30px solid transparent;
        border-left: 30px solid #0036B0;
      }
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    background: #001441;
    align-items: center;
    height: 100%;
    margin-top: 80px;

    @media (min-width: 1024px) {
      align-items: initial;
      padding-left: 95px;
      justify-content: center;
      margin-top: 0;
    }

    &--name {
      color: #FFFFFF;
      font-size: 30px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 36px;
      margin-top: 26px;
      margin-bottom: 0;
    }

    &--position {
      display: inline-block;
      opacity: 0.7;
      color: #FFFFFF;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 24px;
      margin: 12px 0 18px 0;

      @media (min-width: 1024px) {
        font-size: 16px;
      }
    }

    &--email,
    &--phone {
      position: relative;
      width: fit-content;
      text-decoration: none;
      color: #FFFFFF;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 30px;
      margin-bottom: 19px;

      @media (min-width: 1024px) {
        font-size: 18px;
      }

      &:hover {
        &::after {
          width: 100%;
        }
      }

      &::after {
        position: absolute;
        display: block;
        content: '';
        width: 0;
        height: 1px;
        color: #11BFDA;
        background: #11BFDA;
        transition: all .3s;
      }
    }
  }

  &--title {
    color: #FFFFFF;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0;
    margin-bottom: 24px;
    line-height: normal;
    @media (min-width: 568px) {
      font-size: 30px;
      line-height: 48px;
    }

    @media (min-width: 1024px) {
      font-size: 42px;
      width: 80%;
    }
  }

  &--subtitle {
    opacity: 0.9;
    color: #FFFFFF;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 30px;
    margin-bottom: 36px;
  }
}
</style>
